export const HOST_URL = process.env.REACT_APP_API_ENDPOINT;
export const HOST_URL_BIZ = process.env.REACT_APP_API_BIZ_ENDPOINT;

export const AUTH = {
  VERIFY_EMAIL: '/auth/email',
  LOGIN: '/auth/login',
  FORGOT_PASSWORD: '/auth/forgot-password',
  RESET_PASSWORD: '/auth/reset-password',
  LOGOUT: '/auth/logout',
  LOGIN_MS: '/auth/login_msentra',
  RENEW_TOKEN: '/auth/renew_token',
  CHANGE_PIN: `/auth/change_pin`,
};

export const ACCOUNTS = {
  LIST: '/users',
  DETAIL: (id: string) => `/users/${id}/profile`,
  EXPORT_EXCEL: '/users/export-excel',
  UPLOAD: '/google-cloud/uploads',
  UPLOAD_AVATAR: '/google-cloud/upload/avatar',
  LOCK: (id: string) => `/users/${id}/lock`,
  UNLOCK: (id: string) => `/users/${id}/unlock`,
  ACTIVE_ACCOUNT: (id: string) => `/security-accounts/${id}/active`,
  FORTFOLIO: (id: string) => `/security-accounts/${id}/portfolio`,
  CASH_BALANCE: (id: string) => `/security-accounts/${id}/cash-balance`,
  LOCK_WITHDRAW: (id: string) => `/security-accounts/${id}/withdrawal/status`,
  CLIENT: (id: string) => `/sbbs/account/${id}`,
  SYNC_CLIENT: (id: string) => `/sbbs/account/${id}/sync`,
};

export const ADMINS = {
  CHANGE_PASSWORD: (id: string) => `/admins/${id}/change-password`,
};

export const ASSIGNMENTS = {
  GET: '/assignments',
};

export const DEPOSITS = {
  LIST: '/deposits/logs',
  REFUND: (id: string) => `/deposits/${id}/refund`,
  MARK_SUCCESS: (id: string, movement_id: string) => `/deposits/${id}/mark-success/${movement_id}`,
  EXPORT_DATA: '/deposits/uncompleted-export-excel',
};

export const TRANSACTION = {
  LIST: '/fs-internal-orders',
  DETAILS: (id: string) => `/fs-internal-orders/${id}`,
  DESTROY_ORDERS: '/fs-internal-orders/destroy',
};

export const WITHDRAWS = {
  LIST: '/withdraws/logs',
  DETAILS: (id: string) => `/withdraws/logs/${id}`,
  APPROVED: (id: string) => `/withdraws/${id}/approve`,
  EXPORT_DATA: '/withdraws/reconciliation-export-excel',
};

export const ANNOUNCEMENT = {
  LIST: '/announcement',
  DETAILS: (id: string) => `/announcement/${id}`,
  CREATE: '/announcement',
  UPDATE: (id: string) => `/announcement/${id}`,
  DELETE: (id: string) => `/announcement/${id}`,
  GET_URL_UPLOAD: '/cloudstorage/signed-url',
  DELETE_FILE: (announcement_id: string, file_id: string) =>
    `/announcement/${announcement_id}/file/${file_id}`,
  CREATE_FILE: (id: string) => `/announcement/${id}/file`,
  UPDATE_FILE: (announcement_id: string, file_id: string) =>
    `/announcement/${announcement_id}/file/${file_id}`,
};

export const TCB = {
  LIST: '/tcb/report-files',
  GET_URL_DOWNLOAD: (id: string) => `/tcb/report-files/${id}/download-url`,
};

export const SETTING_SYSTEM = {
  UPDATE: (id: string) => `/setting-system/${id}`,
};

export const USERS = {
  LIST: '/users',
  GET_USER_BY_ID: (id: string) => `/users/${id}`,
  GET_PDF_CONTRACT: (id: string, contract_type: string) =>
    `/users/${id}/export-contract-pdf/${contract_type}`,
  ACTIVE_USER: (id: string) => `/users/${id}/active`,
  DEACTIVE_USER: (id: string) => `/users/${id}/deactive`,
  LOCK_USER: (id: string) => `/users/${id}/lock`,
  RE_EKYC: (id: string) => `/users/${id}/ekyc/status`,
  APPROVE_EKYC: (id: string) => `/users/${id}/approve-ekyc`,
  UNLOCK_EKYC: (id: string) => `/users/${id}/unlock-ekyc`,
  GET_DEPOSIT_BY_USER: (id: string) => `/users/${id}/deposits`,
  GET_WITHDRAW_BY_USER: (id: string) => `/users/${id}/withdraws`,
  RESTORE_USER: (id: string) => `/users/${id}/recovery`,
  GET_FS_INTERNAL_ORDERS: (id: string) => `/users/${id}/fs_internal_orders`,
  GET_FS_STOCK_ADVANCE_ORDERS: (id: string) => `/users/${id}/fs_stock_advance_orders`,
  GET_STOCK_WAITING_TO_RECEIVE: (id: string) => `/users/${id}/stock_waiting_to_receive`,
  GET_CASH_WAITING_TO_RECEIVE: (id: string) => `/users/${id}/cash_waiting_to_receive`,
  GET_BOND_TRANSACTIONS_BY_USER: (id: string) => `/users/${id}/bond-transactions`,
  CREATE_ACCOUNT: (id: string) => `/users/${id}/account/create`,
  GET_URL_UPLOAD: '/cloudstorage/contract-url',
  CREATE_CONTRACT_FILE: (user_id: string) => `/users/${user_id}/account/create-contract-file`,
  DELETE_CONTRACT_FILE: (user_id: string, file_id: string) =>
    `/users/${user_id}/account/delete-contract-file/${file_id}`,
  GET_OCR: (id: string) => `/users/${id}/ocr`,
  UPLOADFILE_EDITED: (id: string) => `/users/${id}/ekyc/uploadfile-edited`,
  UPLOADFILE_EDITED_LOG: (id: string) => `/users/${id}/ekyc/uploadfile-edited-log`,
  UPDATE_ACCOUNT_G3_INFO: (id: string) => `/users/${id}/account/update-info`,
};

export const ARTICLE_CATEGORIES = {
  LIST: '/article-categories/search',
  GET_ARTICLE_CATEGORY_BY_ID: (id: string) => `/article-categories/${id}`,
  GET_ARTICLE_CATEGORY_BY_TYPE: '/article-categories',
  ADD_ARTICLE_CATEGORY: '/article-categories',
  UPDATE_ARTICLE_CATEGORY_BY_ID: (id: string) => `/article-categories/${id}`,
  DELETE_ARTICLE_CATEGORY_BY_ID: (id: string) => `/article-categories/${id}`,
  ACTIVE_ARTICLE_CATEGORY_BY_ID: (id: string) => `/article-categories/${id}/active`,
  DEACTIVE_ARTICLE_CATEGORY_BY_ID: (id: string) => `/article-categories/${id}/deactive`,
  ADD_TAG: (id: string) => `/article-categories/${id}/tags`,
  UPDATE_TAG: (id: string) => `/article-categories/${id}/tags`,
  DELETE_TAG: (id: string) => `/article-categories/${id}/tags`,
  UPLOAD: '/google-cloud/uploads',
};

export const ARTICLES = {
  LIST: '/articles',
  ADD: '/articles',
  GET_ARTICLE_BY_ID: (id: string) => `/articles/${id}`,
  UPDATE_ARTICLE_BY_ID: (id: string) => `/articles/${id}`,
  DELETE_ARTICLE_BY_ID: (id: string) => `/articles/${id}`,
  APPROVE: (id: string) => `/articles/${id}/approve`,
  REJECT: (id: string) => `/articles/${id}/reject`,
};

export const ARTICLE_IMAGE_LIBRARY = {
  LIST: `/google-cloud/images-gallery`,
  ADD: `/google-cloud/images-gallery`,
  DELETE: (id: string) => `/google-cloud/images-gallery/${id}`,
};

export const ARTICLE_QUIZ = {
  LIST: (id: string) => `/articles/${id}/quiz`,
  ADD_OR_UPDATE: (id: string) => `/articles/${id}/quiz/add-or-update`,
};

export const ARTICLE_TAGS = {
  LIST: '/tags',
  DETAIL_BY_ID: (id: string) => `/tags/${id}`,
  ADD: `/tags/create`,
  UPDATE: (id: string) => `/tags/${id}`,
  DELETE: (id: string) => `/tags/${id}`,
  UPDATE_IS_SHOW: (id: string) => `/tags/${id}`,
  GET_PAGE_SECTION: `/tags/page-position`,
  ADD_PAGE_SECTION: `/tags/create-page-map`,
  UPDATE_PAGE_SECTION: `/tags/update-page-map`,
  UPDATE_STATUS: (id: string) => `/tags/update-active-status/${id}`,
  GET_CATEGORIES_BY_TAG_ID: (id: string) => `/tags/${id}/categories`,
};

export const FS_STOCK_ADVANCE_ORDERS = {
  LIST: '/fs-stock-advance-orders',
};

export const ACCOUNT_ASSET_WAITING_AVAILABLES = {
  LIST_STOCK: '/account_asset_waiting_availables/stocks',
};

export const CRONJOB_HISTORIES = {
  LIST: '/cronjob-histories',
};

export const SYSTEM_MONITOR = {
  FS_INTERNAL_ORDER_STATUS_STATISTICS: '/system-monitor/fs-internal-order-status-statistics',
  BID_FS_INTERNAL_ORDER_DUPLICATE_STOCK: '/system-monitor/bid-fs-internal-order-duplicate-stock',
  ASK_FS_INTERNAL_ORDER_DUPLICATE_CASH: '/system-monitor/ask-fs-internal-order-duplicate-cash',
  SECURITY_ACCOUNT_STOCK_DEBIT_GREATER_CREDIT:
    '/system-monitor/security-account-stock-debit-greater-credit',
  WRONG_SE101_STOCK_LEDGE_BALANCE: '/system-monitor/wrong-se101-stock-ledge-balance',
  DUPLICATE_ACCOUNT: (type: any) => `/system-monitor/duplicate-account/${type}`,
  SYSTEM_MONITOR_INFO: `/system-monitor`,
};

export const BONDS = {
  LIST: '/bonds',
  ADD_BOND: '/bonds',
  GET_BOND_BY_ID: (id: string) => `/bonds/${id}`,
  UPDATE_BOND_BY_ID: (id: string) => `/bonds/${id}`,
  UPLOAD_IMAGE: '/google-cloud/upload/fixed-income',
};

export const BOND_TRANSACTIONS = {
  LIST: '/bond-transactions',
};

export const CONTRACT = {
  SYSTEM_CONFIG: '/biz/system-config',
};

export const NOTIFICATIONS = {
  LIST_CONFIGS: '/notifications/configs',
  CONFIG_DETAIL: (id: string) => `/notifications/configs/${id}`,
  LIST_TEMPLATES: '/notifications/templates',
  TEMPLATE_DETAIL: (id: string) => `/notifications/templates/${id}`,
  UPDATE_TEMPLATE: (id: string) => `/notifications/templates/${id}`,
  BROADCAST: '/notifications/broadcast',
  LIST_NOTIFICATIONS: '/notifications',
  LIST_BROADCAST_TEMPLATE: '/notifications/broadcast-templates',
};

export const PUSH_NOTIFICATION = {
  // get user by accountno
  GET_USER_BY_ACCOUNT_NO: `/portal/notification/get-users`,

  // get Action type
  LIST_ACTION_TYPE: '/portal/notification/notification-action-type',

  // content
  LIST_CONTENT: `/portal/notification/content`,
  ADD_CONTENT: `/portal/notification/content`,
  DETAIL_CONTENT: (id: string) => `/portal/notification/content/${id}`,
  UPDATE_CONTENT: (id: string) => `/portal/notification/content/${id}`,
  DELETE_CONTENT: (id: string) => `/portal/notification/content/${id}`,
  ENABLE_CONTENT: (id: string) => `/portal/notification/content/${id}/enable`,
  DISABLE_CONTENT: (id: string) => `/portal/notification/content/${id}/disable`,
  LIST_CATEGORY_CONTENT: `/portal/notification/content/categories`,

  //recruiment
  LIST_RECRUIMENT: `/recruiment-news`,
  ADD_RECRUIMENT: `/recruiment-news`,
  DETAIL_RECRUIMENT: (id: string) => `/recruiment-news/${id}`,
  UPDATE_RECRUIMENT: (id: string) => `/recruiment-news/${id}`,
  DELETE_RECRUIMENT: (id: string) => `/recruiment-news/${id}`,

  // User Segment
  LIST_USER_SEGMENT: `/portal/notification/user-segment`,
  ADD_USER_SEGMENT: `/portal/notification/user-segment`,
  DETAIL_USER_SEGMENT: (id: string) => `/portal/notification/user-segment/${id}`,
  UPDATE_USER_SEGMENT: (id: string) => `/portal/notification/user-segment/${id}`,
  DELETE_USER_SEGMENT: (id: string) => `/portal/notification/user-segment/${id}`,
  ENABLE_USER_SEGMENT: (id: string) => `/portal/notification/user-segment/${id}/enable`,
  DISABLE_USER_SEGMENT: (id: string) => `/portal/notification/user-segment/${id}/disable`,
  EXPORT: (id: string) => `/portal/notification/user-segment/${id}/export`,

  // User Segment Input
  ADD_USER_SEGMENT_INPUT: `/portal/notification/user-segment-input`,
  UPDATE_USER_SEGMENT_INPUT: (id: string) => `/portal/notification/user-segment-input/${id}`,
  DELETE_USER_SEGMENT_INPUT: (id: string) => `/portal/notification/user-segment-input/${id}`,
  DETAIL_USER_SEGMENT_INPUT: (id: string) => `/portal/notification/user-segment-input/${id}`,
  LIST_USER_SEGMENT_INPUT: (id: string) => `/portal/notification/user-segment-input/${id}/user`,

  // Template Push
  LIST_TEMPLATE_PUSH: `/portal/notification/template-push`,
  ADD_TEMPLATE_PUSH: `/portal/notification/template-push`,
  DETAIL_TEMPLATE_PUSH: (id: string) => `/portal/notification/template-push/${id}`,
  UPDATE_TEMPLATE_PUSH: (id: string) => `/portal/notification/template-push/${id}`,
  DELETE_TEMPLATE_PUSH: (id: string) => `/portal/notification/template-push/${id}`,
  ENABLE_TEMPLATE_PUSH: (id: string) => `/portal/notification/template-push/${id}/enable`,
  DISABLE_TEMPLATE_PUSH: (id: string) => `/portal/notification/template-push/${id}/disable`,
};

export const SURVEYS = {
  GET_SURVEYS: '/surveys',
  GET_SURVEY_BY_CODE: (code: string) => `/surveys/${code}`,
};

export const SURVEY_SECTION = {
  GET_SURVEY_SECTIONS: '/survey-sections',
  GET_SURVEY_SECTION_BY_CODE: (code: string) => `/survey-sections/${code}`,
  UPDATE_SURVEY_SECTION_BY_CODE: (code: string) => `/survey-sections/${code}`,
  ENABLE_SURVEY_SECTION_BY_CODE: (code: string) => `/survey-sections/${code}/enable`,
  DISABLE_SURVEY_SECTION_BY_CODE: (code: string) => `/survey-sections/${code}/disable`,
};

export const SURVEY_QUESTION = {
  GET_SURVEY_QUESTIONS: '/survey-questions',
  ADD_SURVEY_QUESTION: '/survey-questions',
  GET_SURVEY_QUESTION_BY_CODE: (code: string) => `/survey-questions/${code}`,
  UPDATE_SURVEY_QUESTION_BY_CODE: (code: string) => `/survey-questions/${code}`,
  ENABLE_SURVEY_QUESTION_BY_CODE: (code: string) => `/survey-questions/${code}/enable`,
  DISABLE_SURVEY_QUESTION_BY_CODE: (code: string) => `/survey-questions/${code}/disable`,
  SWAP_INDEX_SURVEY_QUESTION: '/survey-questions/swap-index',
};

export const MATRIX_RISKS = {
  GET_MATRIX_RISKS: '/matrix-risks',
  UPDATE_MATRIX_RISK_BY_ID: (id: string) => `/matrix-risks/${id}`,
};

export const SUGGESTION_ASSET = {
  GET_SUGGESTION_ASSETS: '/suggestion-asset',
  GET_SUGGESTION_ASSET_BY_ID: (id: string) => `/suggestion-asset/${id}`,
  UPDATE_SUGGESTION_ASSET_BY_ID: (id: string) => `/suggestion-asset/${id}`,
  DISABLE_SUGGESTION_ASSET_ITEM_BY_ID: (item_id: string) =>
    `/suggestion-asset/detail/${item_id}/disable`,
  ENABLE_SUGGESTION_ASSET_ITEM_BY_ID: (item_id: string) =>
    `/suggestion-asset/detail/${item_id}/enable`,
};

export const PORTFOLIO_PLANNING_ASSET_RATIO = {
  GET_ASSET_RATIOS: '/asset-ratios',
  GET_ASSET_RATIO_BY_ID: (id: number) => `/asset-ratios/${id}`,
  UPDATE_ASSET_RATIO_BY_ID: (id: number) => `/asset-ratios/${id}`,
};

export const APPLICATION_MENUS = {
  GET_APPLICATION_MENUS: '/application-menu',
  GET_APPLICATION_MENU_BY_ID: (id: string) => `/application-menu/${id}`,
  UPDATE_APPLICATION_MENU_BY_ID: (id: string) => `/application-menu/${id}`,
  DISABLE_APPLICATION_MENU_BY_ID: (id: string) => `/application-menu/${id}/disable`,
  ENABLE_APPLICATION_MENU_BY_ID: (id: string) => `/application-menu/${id}/enable`,
  UPLOAD_ICON_MENU: `/google-cloud/upload/menu`,
};

export const TITITADA_RIGHT = {
  GET_TITITADA_RIGHTS: '/tititada-rights',
  ADD_TITITADA_RIGHT: '/tititada-rights',
  GET_TITITADA_RIGHT_BY_ID: (id: string) => `/tititada-rights/${id}`,
  CLOSE_TITITADA_RIGHT_BY_ID: (id: string) => `/tititada-rights/${id}/close`,
  EDIT_ACTIVE_DATE_TITITADA_RIGHT_BY_ID: (id: string) => `/tititada-rights/${id}/edit-active-date`,
};

export const ACCOUNT_RIGHT = {
  GET_ACCOUNT_RIGHTS: '/account-right',
  GET_ACCOUNT_RIGHT_TRANSACTION: '/account-right/transaction',
};

export const PORTFOLIO_PLANNING_FILTERS = {
  GET_PORTFOLIO_PLANNING_FILTERS: '/stock-criteria-templates',
  ADD_PORTFOLIO_PLANNING_FILTERS: '/stock-criteria-templates',
  GET_PORTFOLIO_PLANNING_FILTER_BY_ID: (id: string) => `/stock-criteria-templates/${id}`,
  UPDATE_PORTFOLIO_PLANNING_FILTER_BY_ID: (id: string) => `/stock-criteria-templates/${id}`,
  GET_CRITERIA_TYPES: '/stock-criteria-templates/criteria',
  GET_CRITERIA_TYPES_DESCRIPTION: '/stock-criteria-templates/criteria/description',
  SYNC_PORTFOLIO_PLANNING_FILTERS: (id: string) => `/stock-criteria-templates/${id}/on`,
};

export const FAQS = {
  GET_FAQS: '/faq',
  GET_FAQ_BY_ID: (id: string) => `/faq/${id}`,
  ADD_FAQ: '/faq',
  UPDATE_FAQ_BY_ID: (id: string) => `/faq/${id}`,
  ENABLE_FAQ_BY_ID: (id: string) => `/faq/${id}/enable`,
  DISABLE_FAQ_BY_ID: (id: string) => `/faq/${id}/disable`,
};

export const ARTICLE_TOPICS = {
  GET_ARTICLE_TOPICS: '/article-topics/search',
  GET_ARTICLE_TOPIC_BY_ID: (id: string) => `/article-topics/${id}`,
  GET_ARTICLE_TOPIC_BY_CATEGORY_ID: '/article-topics',
  ADD_ARTICLE_TOPIC: '/article-topics',
  UPDATE_ARTICLE_TOPIC_BY_ID: (id: string) => `/article-topics/${id}`,
  DELELE_ARTICLE_TOPIC_BY_ID: (id: string) => `/article-topics/${id}`,
};

export const HOME_SLIDES = {
  GET_HOME_SLIDES: '/slides',
  GET_HOME_SLIDE_BY_ID: (id: string) => `/slides/${id}`,
  ADD_HOME_SLIDE: '/slides',
  UPDATE_HOME_SLIDE_BY_ID: (id: string) => `/slides/${id}`,
  ENABLE_HOME_SLIDE_BY_ID: (id: string) => `/slides/${id}/enable`,
  DISABLE_HOME_SLIDE_BY_ID: (id: string) => `/slides/${id}/disable`,
  UPLOAD_IMAGE: () => `/google-cloud/upload/slides`,
};

export const HOME_SLIDE_V2 = {
  // home slide
  HOME_SLIDES_GET_LIST: '/home-slides',
  HOME_SLIDES_GET_DETAIL_BY_ID: (id: string) => `/home-slides/${id}`,
  HOME_SLIDES_UPDATE_BY_ID: (id: string) => `/home-slides/${id}`,

  // theme
  THEMES_GET_LIST: '/home-slides/themes',
  THEMES_GET_DETAIL_BY_ID: (id: string) => `/home-slides/themes/${id}`,
  THEMES_UPDATE_BY_ID: (id: string) => `/home-slides/themes/${id}`,
  THEMES_UPDATE_STATUS_BY_ID: (id: string) => `/home-slides/themes/${id}/status`,
  THEMES_ADD: '/home-slides/themes',
  THEMES_DROPDOWN: (slideId: string) => `/home-slides/${slideId}/themes/dropdown`,

  // content
  CONTENTS_GET_LIST: '/home-slides/contents',
  CONTENTS_GET_DETAIL_BY_ID: (id: string) => `/home-slides/contents/${id}`,
  CONTENTS_UPDATE_BY_ID: (id: string) => `/home-slides/contents/${id}`,
  CONTENTS_UPDATE_STATUS_BY_ID: (id: string) => `/home-slides/contents/${id}/status`,
  CONTENTS_DELETE_BY_ID: (id: string) => `/home-slides/contents/${id}`,
  CONTENTS_ADD: '/home-slides/contents',

  // media
  MEDIAS_GET_LIST: '/home-slides/medias',
  MEDIAS_GET_DETAIL_BY_ID: (id: string) => `/home-slides/medias/${id}`,
  MEDIAS_UPDATE_BY_ID: (id: string) => `/home-slides/medias/${id}`,
  MEDIAS_UPDATE_STATUS_BY_ID: (id: string) => `/home-slides/medias/${id}/status`,
  MEDIAS_DELETE_BY_ID: (id: string) => `/home-slides/medias/${id}`,
  MEDIAS_ADD: '/home-slides/medias',
};

export const SLIDE_MANAGEMENT = {
  GET_TOP_SLIDES: '/sliders',
  GET_TOP_SLIDE_BY_TYPE: (type: any) => `/sliders/type/${type}`,
  ADD_TOP_SLIDE: '/sliders',
  UPDATE_TOP_SLIDE_BY_ID: (id: string) => `/sliders/${id}`,
};

export const APPLICATION_SETTINGS = {
  GET_APPLICATION_SETTTINGS: '/application-settings',
  GET_APPLICATION_SETTTING_BY_CODE: (code: number) => `/application-settings/${code}`,
  ADD_APPLICATION_SETTTING: '/application-settings',
  UPDATE_APPLICATION_SETTTING_BY_ID: (id: string) => `/application-settings/${id}`,
  DISABLE_APPLICATION_SETTTING_BY_ID: (id: string) => `/application-settings/${id}/disable`,
  ENABLE_APPLICATION_SETTTING_BY_ID: (id: string) => `/application-settings/${id}/enable`,
  UPDATE_IMAGE_APPLICATION_SETTTING: () => '/google-cloud/upload/settings',
};

export const STOCK_METADATA = {
  GET_STOCK_METADATAS: '/app-meta/stock-symbol-metadata',
  GET_STOCK_METADATA_BY_SYMBOL: (symbol: string) => `/app-meta/stock-symbol-metadata/${symbol}`,
  UPDATE_STOCK_METADATA_BY_SYMBOL: (symbol: string) => `/app-meta/stock-symbol-metadata/${symbol}`,
};

export const NEW_PROMOTION_MANAGEMENT = {
  // user
  GET_REWARD_USER: '/reward-user',

  // event
  GET_PROMOTION_EVENTS: '/promotion',
  GET_PROMOTION_EVENT_BY_ID: (id: string) => `/promotion/${id}`,
  ADD_PROMOTION_EVENT: '/promotion',
  UPDATE_PROMOTION_EVENT_BY_ID: (id: string) => `/promotion/${id}`,
  ENABLE_PROMOTION_EVENT_BY_ID: (id: string) => `/promotion/${id}/enable`,
  DISABLE_PROMOTION_EVENT_BY_ID: (id: string) => `/promotion/${id}/disable`,

  // stage
  GET_EVENT_STAGES_BY_ID: (id: string) => `/stage/${id}`,
  UPDATE_EVENT_STAGES_BY_ID: (id: string) => `/stage/${id}`,
  ADD_EVENT_STAGE: `/stage`,
  ENABLE_EVENT_STAGE_BY_ID: (id: string) => `/stage/${id}/enable`,
  DISABLE_EVENT_STAGE_BY_ID: (id: string) => `/stage/${id}/disable`,

  // package
  GET_EVENT_PACKAGES_BY_ID: (id: string) => `/package/${id}`,
  UPDATE_EVENT_PACKAGES_BY_ID: (id: string) => `/package/${id}`,
  ADD_EVENT_PACKAGE: `/package`,
  ENABLE_EVENT_PACKAGE_BY_ID: (id: string) => `/package/${id}/enable`,
  DISABLE_EVENT_PACKAGE_BY_ID: (id: string) => `/package/${id}/disable`,

  // item
  GET_EVENT_ITEMS_BY_ID: (id: string) => `/item/${id}`,
  UPDATE_EVENT_ITEMS_BY_ID: (id: string) => `/item/${id}`,
  ADD_EVENT_ITEM: `/item`,
  ENABLE_EVENT_ITEM_BY_ID: (id: string) => `/item/${id}/enable`,
  DISABLE_EVENT_ITEM_BY_ID: (id: string) => `/item/${id}/disable`,

  //upload banner, icon event
  PROMOTION_EVENT_UPLOAD_BANNER: '/google-cloud/upload/promotion',
};

export const SLIDE_ADAPTIVE_LIBRARY = {
  GET_SLIDES_THEMES: '/slides/themes',
  GET_SLIDES_THEME_BY_ID: (id: string) => `/slides/themes/${id}`,
  UPDATE_SLIDES_THEME_BY_ID: (id: string) => `/slides/themes/${id}`,
  ADD_SLIDES_THEME: '/slides/themes',
  GET_SLIDES_MEDIA: '/slides/media',
  GET_SLIDES_MEDIA_BY_ID: (id: string) => `/slides/media/${id}`,
  UPDATE_SLIDES_MEDIA_BY_ID: (id: string) => `/slides/media/${id}`,
  ADD_SLIDES_MEDIA: '/slides/media',
  UPDATE_SLIDES_MEDIA_STATUS: (id: string, is_active: any) =>
    `/slides/media/${id}/status/${is_active}`,
  GET_SLIDES_CONTENTS: '/slides/contents',
  GET_SLIDES_CONTENTS_BY_ID: (id: string) => `/slides/contents/${id}`,
  UPDATE_SLIDES_CONTENTS_BY_ID: (id: string) => `/slides/contents/${id}`,
  ADD_SLIDES_CONTENTS: '/slides/contents',
  UPDATE_SLIDES_CONTENTS_STATUS: (id: string, is_active: any) =>
    `/slides/contents/${id}/status/${is_active}`,
  ADD_SLIDE_ADAPTIVE_V2: '/slides/adaptive',
  UPDATE_SLIDE_ADAPTIVE_V2_BY_ID: (id: string) => `/slides/adaptive/${id}`,
  GET_SLIDE_BY_ID: (id: string) => `/slides/${id}`,
};

export const ENTRUSTMENT_GROUPS = {
  LIST: `/entrustment-groups`,
  DETAIL_BY_ID: (id: string) => `/entrustment-groups/${id}`,
  ADD: `/entrustment-groups`,
  UPDATE: (id: string) => `/entrustment-groups/${id}`,
  DELETE: (id: string) => `/entrustment-groups/${id}`,
  VERIFY_USER: `/entrustment-groups/verify-user`,
  LIST_USER: (id: string) => `/entrustment-groups/${id}/users`,
  ADD_USER: (id: string) => `/entrustment-groups/${id}/users`,
  DELETE_USER: (id: string, userId: string) => `/entrustment-groups/${id}/users/${userId}`,

  // order
  ORDER: `/entrustments/order`,
  LIST_ORDER: `/entrustments/orders`,
  ORDER_BY_ID: (id: string) => `/entrustments/orders/${id}`,
  CANCEL_ORDER: (id: string) => `/entrustments/orders/${id}/cancel`,
  ADVANCE_ORDER: `/entrustments/advance-orders`,
  ADVANCE_ORDER_DETAIL: (id: string) => `/entrustments/advance-orders/${id}`,
  SUBMIT_BOND_ORDER: `/entrustments/bond-orders`,
  LIST_BOND_ORDER: `/entrustments/bond-orders`,
  GET_BOND_ORDER_BY_ID: (id: string) => `/entrustments/bond-orders/${id}`,
};

export const INFORGRAPHIC_VIDEO = {
  LIST: '/inforgraphic-video',
  ADD: `/inforgraphic-video`,
  DETAIL_BY_ID: (id: string) => `/inforgraphic-video${id}`,
  UPDATE: (id: string) => `/inforgraphic-video/${id}`,
  UPDATESTATUS: (id: string) => `/inforgraphic-video/${id}/outstading`,
  DELETE: (id: string) => `/inforgraphic-video/${id}/status`,
  UPLOAD: `/google-cloud/upload/inforgraphic-video`,
};

export const UPLOAD = {
  UPLOAD_VIDEO_URL: `/google-cloud/upload/video/upload-url`,
  UPLOAD_IMAGE_URL: `/cloudstorage/signed-url`,
};

export const CONFETTI = {
  LIVE: (id?: string) => `/service-confetti/portal/api/v1/confetti/live${id ? `/${id}` : ''}`,
  LIVE_QUESTIONS: (id: string) => `/service-confetti/portal/api/v1/confetti/live/${id}/quiz`,
  UPDATE_QUESTION: (idLive: string, idQuestion: string, action: 'show' | 'hide') =>
    `/service-confetti/portal/api/v1/confetti/live/${idLive}/quiz/${idQuestion}/${action}`,
  RECUS_USER: (id: string) => `/service-confetti/portal/api/v1/confetti/live/${id}/rescus`,
  UPDATE_STATUS_LIVE: (id: string, action: 'start' | 'stop') =>
    `/service-confetti/portal/api/v1/confetti/live/${id}/${action}`,
  UPDATE_FAKE_NUMBER: (id: string) =>
    `/service-confetti/portal/api/v1/confetti/live/${id}/fake-number`,
  GET_FIREBASE_TOKEN: `/custom-auth`,
};

export const ACADEMY = {
  HEADER: '/tags/academy-tag-header',
  HEADER_UPDATE: '/tags/academy-tag-header/update',
};

export const BANNER = (id?: string) => `/banner${id ? `/${id}` : ''}`;

export const REFERRAL_CODE = (id?: string) => `/referralcodes${id ? `/${id}` : ''}`;

export const DAY_END_LOGS = {
  LIST: '/dayendlogs',
  START_DE: '/dayendlogs/start',
  END_DE: '/dayendlogs/end',
  DE_STATUS: '/biz/system-config/de-status',
};

export const SYSTEM = {
  RESTART_G2G3: '/system/remove-g2-session',
  RESTART_ORDER_PUSH: '/system/restart-order-push-service',
};

export const FAQ_PORTAL = {
  // category
  GET_LIST_CATEGORY: '/faq/category',
  ADD_CATEGORY: '/faq/category',
  GET_DETAIL_CATEGORY: (id: string) => `/faq/category/${id}`,
  UPDATE_CATEGORY: (id: string) => `/faq/category/${id}`,
  DELETE_CATEGORY: (id: string) => `/faq/category/${id}`,
  STATUS_CATEGORY: (id: string) => `/faq/category/${id}/status`,

  // article
  GET_LIST_ARTICLE: '/faq',
  ADD_ARTICLE: '/faq',
  DETAIL_ARTICLE: (id: string) => `/faq/${id}`,
  UPDATE_ARTICLE: (id: string) => `/faq/${id}`,
  DELETE_ARTICLE: (id: string) => `/faq/${id}`,
};

export const USER = {
  LIST: '/usermanager',
  ADD: '/usermanager/user-role-permissions',
  DETAIL_BY_ID: (id?: string) => `/usermanager${id ? `/${id}` : ''}`,
  ADD_ROLE: (id: string) => `/usermanager/${id}/role`,
  ADD_PERMISSION: (id: string) => `/usermanager/${id}/permission`,
};

export const NEWS = (id?: string) => `/newsevents${id ? `/${id}` : ''}`;
